import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousels from '../components/carousel'
import ReactMarkdown from 'react-markdown'
import React from 'react'
import moment from 'moment'
import DynamicZone from '../helpers/dynamicZone'

const NotesPage = (props) => {
  
  const { pageContext, data } = props
  const { navbar } = pageContext
  const { strapiNotes: notes } = data
  
  const objCarousel = {
    visible: true,
    slide: notes.noteHeader
  }
  
  let SEO = ''

  if (notes.pageMetadata !== null) {
    const { description, keywords, pageTitle } = notes.pageMetadata
  
    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  }

  return (
    <Layout page={navbar}>
      {SEO}
      <section className="schedule-feed mb-3">
        {/* Header de sliders */}
        {objCarousel?.slide ? 
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'notePage-'} />
          </div>
        : null}

        {/* Info */}
        <div className="container pt-5 novelties-component">
          <p className="font-primary">
            Publicado el {moment(notes.date).format('DD-MM-YYYY')} en{' '}
            <b>{notes.note_mediums?.map((medium, i) => {
              if ((notes.note_mediums.length - 1) > i) {
                return medium.name + " / "
              } else {
                return medium.name
              }
            })}</b>
          </p>
          <h1 className="mb-4">{notes.title}</h1>
        </div>

        {/* Components de las Notes */}
        <div className="note-page pt-1">
          <DynamicZone components={notes.components} />
        </div>

        {/* Seccion Mas informacion */}
        <div className="container pt-5 note-information">
          <p className="font-primary">
            <b>Medio Publicado: </b>
            <span>
              {notes.note_mediums?.map((medium, i) => {
                if ((notes.note_mediums.length - 1) > i) {
                  return medium.name + " / "
                } else {
                  return medium.name
                }
              })}
            </span> 
          </p>
          <p className="font-primary">
            <b>Temática: </b> 
            <span>
              {notes.novelties_categories.map((noteCategory, i) => {
                if ((notes.novelties_categories.length - 1) > i) {
                  return noteCategory.name + " - "
                } else {
                  return noteCategory.name
                }
              })}
            </span> 
          </p>
          <p className="font-primary">
            <b> Autor/Redactor: </b> 
            <span>
              {notes.note_authors?.map((autor, i) => {
                if ((notes.note_authors.length - 1) > i) {
                  return autor.name + " / "
                } else {
                  return autor.name
                }
              })}
            </span>
          </p>
          <p className="font-primary">
            <b>Fecha de Publicación:</b> <span>{moment(notes.date).format('DD-MM-YYYY')}</span> 
          </p>
          <div className="mb-3">
            <ReactMarkdown
              source={notes.moreInformation}
              escapeHtml={false}
              className="format-markdown-container markdown-format fade-in-image"
            /> 
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotesPage

NotesPage.propTypes = {
  data: PropTypes.object.isRequired
}

export const notesPageQuery = graphql`
  query($noteId: Int) {
    strapiNotes(strapiId: { eq: $noteId }) {
      title
      slug
      date(formatString: "LL", locale: "es-ar")
      moreInformation
      note_authors {
        name
      }
      note_mediums {
        name
      }
      novelties_categories {
        name
      }
      noteHeader {
        content
        imageDescription
        name
        url
        visible
        imageFilter  
        textButton
        backgroundImage {
          url
        }
        backgroundVideo {
          url
        }
        diary {
          slug
        }
        novelty {
          slug
        }
        organizational_unit {
          slug
        }
        page {
          slug
        }
        ucc_career {
          slug
        }
      }
      pageMetadata {
        description: pageDescription
        keywords: pageKeywords
        pageTitle
      }
      components
    }
  }
`

